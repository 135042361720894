import Stack from "@mui/material/Stack";
import React from "react";
import AgentsButton from "./AgentsButton.js";
import AppUpdateButton from "./AppUpdateButton.js";
import AuxiliaryMenuButton from "./AuxiliaryMenuButton.js";
import CarriersButton from "./CarriersButton.js";
import GlobalButton from "./GlobalButton.js";
import HotelBrandsButton from "./HotelBrandsButton.js";
import LoyaltyButton from "./LoyaltyButton.js";
import PrivacyButton from "./PrivacyButton.js";
import { styled } from "@mui/material/styles";

export const OverlayToolbar = styled(Stack)(() => ({
  "@media (display-mode: window-controls-overlay)": {
    position: "fixed",
    top: "env(titlebar-area-y, 0)",
    left: "env(titlebar-area-x, 0)",
    height: "env(titlebar-area-height, 33px)",
    width: "env(titlebar-area-width, 100%)",
    margiRight: 0,
    alignItems: "center",
    justifyContent: "end",
  },
}));

export default function ToolbarControlButtonStack() {
  return (
    <OverlayToolbar direction="row" spacing={1} marginRight={2}>
      <AppUpdateButton />
      <PrivacyButton />
      <GlobalButton />
      <AgentsButton />
      <CarriersButton />
      <HotelBrandsButton />
      <LoyaltyButton />
      <AuxiliaryMenuButton />
    </OverlayToolbar>
  );
}
