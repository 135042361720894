import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import getSegmentFullTitle, { getSegmentStationTitle } from "../../models/getSegmentFullTitle.js";
import getStayFullTitle from "../../models/getStayFullTitle.js";

export function BookingLink({ id, state, title }) {
  if (!title) {
    console.warn(`Booking ${id} is missing title`);
  }
  return (
    <Link to={`/bookings/${id}`} component={ReactRouterLink} state={state}>
      {title || id}
    </Link>
  );
}

export default function BookingTitleLink({ booking, hideStationIds, hideCarrier, isTravel, isStay, state }) {
  const parts = [];
  let auxLabel = null;

  if (booking.stays?.length > 0 && isTravel !== true) {
    const stay = booking.stays[0];
    if (stay) {
      parts.push(getStayFullTitle(stay));
    }
  }

  if (booking.segments?.length > 0 && isStay !== true) {
    if (booking.isReturn) {
      // normal return journey
      const title = getSegmentStationTitle({
        segment: booking.segments[0],
        leadingCarrier: booking.leadingCarrier,
        hideStationIds,
        isReturn: true,
      });

      auxLabel = (
        <Typography component="span" variant="body2" color="secondary">
          {" - Return"}
        </Typography>
      );

      parts.push(title);
    } else if (booking.isConnectedTravelSequence) {
      // Sequential travel, make a shorter title
      const [firstSegments, ...otherSegments] = booking.segments;
      const firstStep = getSegmentFullTitle({
        segment: firstSegments,
        leadingCarrier: booking.leadingCarrier,
        hideStationIds,
        hideCarrier,
      });
      const secondParts = otherSegments
        .map((segment) =>
          getSegmentFullTitle({
            segment,
            leadingCarrier: booking.leadingCarrier,
            hideStationIds,
            hideCarrier,
            hideOrigin: true,
          }),
        )
        .join(" › ");

      parts.push(`${firstStep} › ${secondParts}`);
    } else {
      const title = booking.segments
        .map((segment) =>
          getSegmentFullTitle({ segment, leadingCarrier: booking.leadingCarrier, hideStationIds, hideCarrier }),
        )
        .join(", ");

      parts.push(title);
    }
  }

  if (!parts.length) {
    parts.push(booking.title);
  }

  return (
    <>
      <BookingLink id={booking.id} title={parts.join(" + ")} state={state} />
      {auxLabel}
    </>
  );
}
