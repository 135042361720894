import Tooltip from "@mui/material/Tooltip";
import React from "react";
import IconButton from "@mui/material/IconButton";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import Loyalty from "@mui/icons-material/Loyalty";

const to = "/loyalty";
export default function LoyaltyButton() {
  const { pathname } = useLocation();
  const isActive = pathname === to;
  return (
    <Tooltip title={"Loyalty"} placement="bottom-end">
      <IconButton component={ReactRouterLink} to={to} color={isActive ? "primary" : "inherit"} size="small">
        <Loyalty />
      </IconButton>
    </Tooltip>
  );
}
