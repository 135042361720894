import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { isPast, differenceInCalendarDays } from "date-fns";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useIntl } from "react-intl";

function DueDateLabel({ clearDueDate, prefix }) {
  const { formatDate, formatNumber } = useIntl();
  if (!clearDueDate) return prefix;
  const value = formatDate(clearDueDate, {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "short",
  });

  const daysDifference = differenceInCalendarDays(value, new Date());

  let differenceLabel = "";
  if (daysDifference > 0) {
    differenceLabel = `, in ${formatNumber(daysDifference)} days`;
  } else if (daysDifference < 0) {
    differenceLabel = `, ${formatNumber(-daysDifference)} days ago`;
  }

  return prefix ? `${prefix}: ${value}${differenceLabel}` : value;
}

function SuccessChip({ useChip }) {
  const icon = <CheckCircleIcon color="success" />;

  if (useChip) {
    return <Chip icon={icon} color="success" label="Cleared" size="small" variant="outlined" />;
  }

  return icon;
}

function ErrorChip({ useChip, label }) {
  const icon = <ErrorIcon color="error" />;

  if (useChip) {
    return <Chip icon={icon} color="error" label={label} size="small" variant="outlined" />;
  }

  return icon;
}

function PendingChip({ useChip, label }) {
  const icon = <HourglassTopIcon color="info" />;

  if (useChip) {
    return <Chip icon={icon} color="info" label={label} size="small" variant="outlined" />;
  }

  return icon;
}

function UnknownChip({ useChip, label }) {
  const icon = <HelpOutlineIcon color="default" />;

  if (useChip) {
    return <Chip icon={icon} color="default" label={label} size="small" variant="outlined" />;
  }

  return icon;
}

export default function LoyaltyProgramActivityStatusIcon({ loyaltyProgramActivity, useChip }) {
  if (!loyaltyProgramActivity) return null;
  const { isPending, isCleared, clearDueDate } = loyaltyProgramActivity;

  if (isCleared) {
    return (
      <Tooltip title="Cleared" placement="left">
        <span>
          <SuccessChip useChip={useChip} />
        </span>
      </Tooltip>
    );
  }

  if (clearDueDate && isPast(clearDueDate)) {
    const title = <DueDateLabel clearDueDate={clearDueDate} prefix="Overdue" />;
    return (
      <Tooltip title={title} placement="left">
        <span>
          <ErrorChip useChip={useChip} label="Overdue" />
        </span>
      </Tooltip>
    );
  }

  if (isPending) {
    const title = <DueDateLabel clearDueDate={clearDueDate} prefix="Pending" />;
    return (
      <Tooltip title={title} placement="left">
        <span>
          <PendingChip useChip={useChip} label="Pending" />
        </span>
      </Tooltip>
    );
  }

  if (!isPending && !isCleared) {
    const title = <DueDateLabel clearDueDate={clearDueDate} prefix="Status Unknown" />;

    return (
      <Tooltip title={title} placement="left">
        <span>
          <UnknownChip useChip={useChip} label="Unknown" />
        </span>
      </Tooltip>
    );
  }

  if (useChip) return <Chip icon={<ErrorIcon />} color="warning" label="Unknown" size="small" variant="outlined" />;
  return <ErrorIcon color="warning" />;
}
