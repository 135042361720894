import { db } from "../../models/db.js";

export default async function repairBookingLocationTitle() {
  const result = await db.transaction("rw", [db.bookings, db.stayLocations, db.stays], async () => {
    const stayLocations = await db.stayLocations.toArray();

    for (let i = 0; i < stayLocations.length; i++) {
      const stayLocation = stayLocations[i];

      const updatedObject = {
        id: stayLocation.id,
        title: stayLocation.title,
        timeZone: stayLocation.timeZone,
      };

      await db.stays.where("location.id").equals(stayLocation.id).modify({ location: updatedObject });

      await db.bookings
        .where("leadingStayLocation.id")
        .equals(stayLocation.id)
        .modify({ leadingStayLocation: updatedObject });

      await db.bookings
        .where("leadingStayBrand.id")
        .equals(stayLocation.id)
        .modify((row) => {
          row.stays = row.stays.map((stay) => {
            if (stay.location.id !== stayLocation.id) return stay;

            return { ...stay, location: updatedObject };
          });
        });
    }

    return stayLocations.length;
  });

  console.log(result);
}
