import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import repairBookingLocationTitle from "../../lib/migrations/repairBookingLocationTitle.js";

export default function PerformServiceMenuItem({ onClick }) {
  const { enqueueSnackbar } = useSnackBar();

  const performCheck = async () => {
    try {
      const result = await repairBookingLocationTitle();
      enqueueSnackbar({ message: `Service Complete`, variant: "success" });
    } catch (ex) {
      console.log(ex);
      enqueueSnackbar({ message: `Service Failed.`, variant: "error" });
    }
  };

  const onClickHandler = async () => {
    onClick();
    await performCheck();
  };

  return (
    <MenuItem onClick={onClickHandler} disableRipple disableTouchRipple>
      <ListItemIcon>
        <BuildCircleIcon />
      </ListItemIcon>
      <ListItemText primary="Perform Service" />
    </MenuItem>
  );
}
