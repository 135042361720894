import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import { AutoCompleteListBox, AutoCompleteListItem } from "../BookingEditor/AutocompleteList.js";
import getTimeZones from "./getTimeZones.js";

const limit = 10;

const stringify = (option) => `${option.offset} ${option.timeZone} ${option.timeZoneAbbreviation}`;

function stripDiacritics(string) {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const filterOptions = (options, { inputValue }) => {
  let input = inputValue.trim().replace(/\s+/g, "_"); // trim
  input = input.toLowerCase(); // ignore upper/lower case
  input = stripDiacritics(input); // ignore accents

  const filteredOptions = !input
    ? options
    : options.filter((option) => {
        let candidate = stringify(option);
        candidate = candidate.toLowerCase();
        // candidate = stripDiacritics(candidate);

        return candidate.includes(input);
      });

  return filteredOptions.slice(0, limit);
};

export default function TimeZoneAutoComplete({
  label,
  value,
  onChange,
  clearOnBlur,
  variant,
  sx,
  loading,
  error,
  disabled,
}) {
  const options = getTimeZones();
  const onValueChange = (event, newValue) => {
    if (typeof newValue === "string") {
      onChange({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      onChange({
        title: newValue.inputValue,
      });
    } else {
      onChange(newValue);
    }
  };

  if (loading) return null;

  let valueToProxy = value;

  if (value && !value.timeZone) {
    // we are loading by ID, we need to reload the existing item
    valueToProxy = options.find((option) => option.timeZone === value?.timeZone || value) || value;
  }

  const renderOptionDefault = (props, option) => {
    return (
      <AutoCompleteListItem {...props} key={props.key}>
        {option.timeZone}
      </AutoCompleteListItem>
    );
  };

  return (
    <Autocomplete
      value={valueToProxy}
      onChange={onValueChange}
      autoSelect
      ListboxComponent={AutoCompleteListBox}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur={clearOnBlur}
      disableClearable
      handleHomeEndKeys
      disabled={disabled}
      options={options}
      isOptionEqualToValue={(option, value) => {
        if (option === value) return true;
        if (option?.timeZone === value?.timeZone) return true;
        if (option?.timeZone && option.timeZone === value) return true;

        return false;
      }}
      getOptionLabel={(option) => {
        return option?.timeZone || option;
      }}
      renderOption={renderOptionDefault}
      sx={sx}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          variant={variant || "standard"}
          margin="none"
          error={error}
          label={label}
          autoFocus
          helperText={valueToProxy ? `${valueToProxy?.timeZoneAbbreviation} (${valueToProxy?.offset})` : null}
        />
      )}
      slotProps={{
        paper: { elevation: 8 },
      }}
    />
  );
}
